import React, { useState, useEffect } from "react"
import styled from "styled-components"

const StyledLinks = styled.div`
  position: absolute;
  text-align: right;
  color: white;
  top: 60px;
  right: 0;
  font-size: 1em;
  z-index: 99999;
  display: ${props => (props.isReady ? "flex" : "none")};
  flex-direction: column;
  a {
    transform: ${props =>
      props.isOpen ? "translateY(0)" : "translateY(-400px)"};
    font-size: 1rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: white;
    font-weight: 200;
    margin: 5px 0;
    opacity: ${props => (props.isOpen ? 1 : 0)};
    transition: all 0.15s;
    &:hover {
      opacity: 1;
      color: ${props => props.theme.turquoise()};
    }
  }
`

const Navbar = ({ isOpen, getReady, dark, children }) => {
  // getReady turns to true when the icon that opens this navbar is hovered. That way, the links display becomes visible, but they are still at opacity-0 until the icon is clicked. The drop down animation won't work if the links got from display: none to display: flex instantly.
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (getReady) {
      setIsReady(true)
    }
  }, [getReady])

  const getAnimationDelayCSS = () => {
    const array = []
    for (let i = 0; i < children.length; i++) {
      array.push(`&:nth-child(${i + 1}) {
        transition-delay: ${0.05 * i}s;
        transition-property: transform;
      };`)
    }
    array.join("")
    return array.join("")
  }
  const animationDelayCSS = getAnimationDelayCSS()
  return (
    <StyledLinks
      icon
      isReady={isReady}
      isOpen={isOpen}
      animationDelayCSS={animationDelayCSS}
      dark={dark}
    >
      {children}
    </StyledLinks>
  )
}

export default Navbar
