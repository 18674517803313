import React from "react"
import styled from "styled-components"

const StyledHamburger = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  margin: 0 5px 0 10px;
  padding: 7px 0;
  width: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: visible;
  cursor: pointer;
  z-index: 99999;
  transition: color 0.5s;

  i {
    width: 100%;
    height: 2px;
    background: white;
  }
  #line1,
  #line3 {
    width: 80%;
  }

  &:hover {
    i {
      background: ${props => props.theme.turquoise(1)};
    }
  }

  #line1 {
    transition: background 0.2s ease-in-out, transform 0.3s ease-in-out;
  }

  #line2 {
    transition: background 0.2s ease-in-out 0.2s, transform 0.3s ease-in-out;
  }

  #line3 {
    transition: background 0.2s ease-in-out 0.4s, transform 0.3s ease-in-out;
  }

  ${props =>
    props.open
      ? `
      transform: rotate(45deg);
      
      #line1 {
        transform: translateY(13px) rotate(180deg);
        width: 100%;
      };
      #line2 {
        opacity: 0;
      };
      #line3 { 
        transform: translateY(-12px) rotate(90deg);
        width: 100%
      };
    `
      : null};
`

const HamburgerIcon = ({ onClick, onMouseOver, onMouseLeave, open, white }) => {
  return (
    <StyledHamburger
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      open={open}
      onClick={onClick}
      white={white}
    >
      <i id="line1" />
      <i id="line2" />
      <i id="line3" />
    </StyledHamburger>
  )
}

export default HamburgerIcon
