import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import NavBar from "./Navbar"
import HamburgerIcon from "./HamburgerIcon"

const NavigationLinkContainer = styled.div`
  position: fixed;
  top: 1.2rem;
  right: 1.2rem;
  z-index: 99999999;
  text-align: right;
  div.wrapper {
    position: relative;
  }
`

const NavigationLink = props => {
  const [navIsOpen, setNavIsOpen] = useState(false)
  const [isNavIconHovered, setIsNavIconHovered] = useState(false)

  const close = () => {
    setNavIsOpen(false)
  }

  useEffect(() => {
    window.addEventListener("scroll", close)
  }, [])

  return (
    <NavigationLinkContainer>
      <div className="wrapper">
        <HamburgerIcon
          open={navIsOpen}
          onClick={() => setNavIsOpen(!navIsOpen)}
          onMouseOver={() => setIsNavIconHovered(true)}
          onMouseLeave={() => setIsNavIconHovered(false)}
        />
        <NavBar getReady={isNavIconHovered} isOpen={navIsOpen}>
          <Link to="/" activeClassName="active" onClick={close}>
            HOME
          </Link>
          <Link to="/packages" activeClassName="active" onClick={close}>
            FISHING PACKAGES
          </Link>
        </NavBar>
      </div>
    </NavigationLinkContainer>
  )
}
export default NavigationLink
