import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/Section"
import LogoLink from "../components/LogoLink"
import NavigationLink from "../components/NavigationLink"

const IndexPage = () => (
  <Layout>
    <SEO title="Packages" />
    <LogoLink />
    <NavigationLink />
    <Section
      background="fishOnBoat"
      title="Loreto | Spring 2023"
      bulletPoints={[
        "2 Full Fishing Days",
        "Transfers to and from Airport",
        "Small Group Setting",
        "Bait & Tip Included",
        "Panga Fishing",
        "Many In-shore Species",
      ]}
    />
    <Section
      background="cactusBoat"
      title="Magdalena Bay | Fall 2023"
      bulletPoints={[
        "3 Full Fishing Days",
        "All Meals Included",
        "** Alcoholic Beverages Not Included",
        "Transfers to and from Airport",
        "Small Group Setting",
        "Mangrove Fishing on Pangas",
        "Offshore Fishing on Cruisers",
      ]}
    />
    <Section
      background="bajaRocksSunset"
      title="Christmas Island | 2023"
      bulletPoints={[
        "3 Full Fishing Days",
        "Transfers to and from Airport",
        "Small Group Setting",
        "Bait & Tip Included",
        "In-Shore Guided Fishing",
        "**Customizable based on preference",
      ]}
    />
  </Layout>
)

export default IndexPage
