import React from "react"
import styled from "styled-components"

import ReelBackground from "../components/backgrounds/ReelBackground"
import Turquoise from "../components/backgrounds/Turquoise"
import ElDorado from "../components/backgrounds/ElDorado"
import Rod from "../components/backgrounds/Rod"
import BajaRocksSunset from "../components/backgrounds/BajaRocksSunset"
import FishOnBoat from "../components/backgrounds/FishOnBoat"
import CactusBoat from "../components/backgrounds/CactusBoat"
import BoatOnShore from "../components/backgrounds/BoatOnShore"
import OzWithYellowFin from "./backgrounds/OzWithYellowFin"

const backgroundsMap = {
  reel: <ReelBackground fixed />,
  turquoise: <Turquoise fixed />,
  elDorado: <ElDorado fixed />,
  rod: <Rod fixed />,
  bajaRocksSunset: <BajaRocksSunset fixed />,
  fishOnBoat: <FishOnBoat fixed />,
  cactusBoat: <CactusBoat fixed />,
  boatOnShore: <BoatOnShore fixed />,
  ozWithYellowFin: <OzWithYellowFin fixed />,
}

const StyledSection = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  color: white;
`

const StyledTitle = styled.div`
  height: 134px;
  background-color: ${props => props.theme.darkGreenBlue(0.8)};
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10vw;
`

const StyledCopy = styled.div`
  background-color: ${props => props.theme.darkGray(0.9)};
  color: white;
  padding: 70px 0;
  display: flex;
  align-content: center;
  justify-content: center;
  ul {
    margin: 0;
  }
  li {
    text-align: center;
    list-style: none;
  }
`

const ExtraContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  text-align: center;
`

const Title = ({ children }) => (
  <StyledTitle>
    <h2 className="decTitle">{children}</h2>
  </StyledTitle>
)

const Copy = ({ children }) => (
  <StyledCopy>
    <p>{children}</p>
  </StyledCopy>
)

const BulletPoints = ({ bulletPoints }) => {
  return (
    <StyledCopy>
      <ul>
        {bulletPoints.map(point => (
          <li>{point}</li>
        ))}
      </ul>
    </StyledCopy>
  )
}

export default function Section({
  children,
  title,
  background,
  copy,
  bulletPoints,
}) {
  return (
    <>
      <StyledSection>
        {backgroundsMap[background]}
        <Title>{title}</Title>
      </StyledSection>
      {copy && (
        <Copy>
          {copy}
          <ExtraContainer>{children}</ExtraContainer>
        </Copy>
      )}
      {bulletPoints && <BulletPoints bulletPoints={bulletPoints} />}
    </>
  )
}
