import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import logo from "../images/ozsAdventuresLogoSmall.png"

const StyledLinkWrapper = styled.div`
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 99999;
  height: 6rem;
  width: 6rem;
`

const LogoLink = () => (
  <StyledLinkWrapper>
    <Link to="/">
      <img src={logo} alt="Oz's Baja Adventures Logo" />
    </Link>
  </StyledLinkWrapper>
)

export default LogoLink
